<template>
  <div
    class="overlay"
    :class="{ overlay__show: showOverLay, overlay__video: toggleVideoState }"
  >
    <b-container v-if="!submitScuess">
      <b-row>
        <b-col cols="12" class="text-md-right">
          <b-button
            variant="default"
            class="closeButton font-bold "
            @click="$emit('hide_overLay')"
            >{{ $t("button.close") }}</b-button
          >
        </b-col>
        <template v-if="!toggleVideoState">
          <b-col
            md="6"
            sm="12"
            lg="4"
            v-for="(card, index) in cardsComponents"
            :key="index"
          >
            <section class="card-wrapper">
              <component
                :showOverLay="showOverLay"
                :firstCardDone="firstCardDone"
                :secondCardDone="secondCardDone"
                @handle_next_card="handleNextCard"
                @first_card_data="getFirstCardData"
                @second_card_data="getSecondCardData"
                @data_sucess="submitScuess = !submitScuess"
                :formData="formData"
                :is="card"
              ></component>
            </section>
          </b-col>
        </template>
        <section class="youtube-slide" v-else>
          <iframe
            width="100%"
            height="100%"
            :src="
              `https://www.youtube.com/embed/phQy6EhxlxQ?autoplay=${toggleVideoState}&rel=0&modestbranding=1&autohide=1&controls=0&modestbranding=1&showinfo=0`
            "
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </section>
      </b-row>
    </b-container>
    <SubmitionSucess v-else @close_overlay="handleCloseOveryLay" />
  </div>
</template>

<script>
export default {
  name: "OverLay",
  props: {
    showOverLay: {
      type: Boolean,
      default: false
    }
  },

  components: {
    FirstCard: () => import("@/components/Cards/FirstCard"),
    SecondCard: () => import("@/components/Cards/SecondCard"),
    ThirdCard: () => import("@/components/Cards/ThirdCard"),
    SubmitionSucess: () => import("@/components/SubmitionSucess")
  },
  data() {
    return {
      cardsComponents: ["FirstCard", "SecondCard", "ThirdCard"],
      firstCardDone: false,
      secondCardDone: false,
      formData: {},
      submitScuess: false
    };
  },
  methods: {
    handleCloseOveryLay() {
      this.$emit("close_overlay");
    },
    handleNextCard(cardType) {
      this[cardType] = true;
    },
    getFirstCardData(firstCardData) {
      this.formData = { ...this.formData, ...firstCardData };
    },
    getSecondCardData(secondCardData) {
      this.formData = { ...this.formData, ...secondCardData };
    }
  },
  watch: {
    showOverLay: {
      handler(value) {
        if (value) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflowY = "scroll";
        }
      }
    },
    submitScuess: {
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.submitScuess = false;
          }, 3000);
        }
      },
      immediate: true
    }
  },
  computed: {
    toggleVideoState() {
      return this.$store.getters.getVideoState;
    }
  }
};
</script>
